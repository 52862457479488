@import "../../../styles/standard.module.scss";

.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 1000;
}
.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}
.tab_edit img {
  max-width: 20px;
  margin-right: 5px;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  font-size: $Typography-2;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.btn_small {
  width: 140px;
  height: 42px;
  font-family: $secondary-font-regular;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  padding: 4px;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: 0;
  background-color: $Plain-bg;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  @include btn_secondary;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
  @include btn_secondary;
}
.basic_input:focus-visible {
  outline: none !important;
}
.cat {
  font-family: $secondary-font-bold;
  margin-bottom: 0px;
}
.recommended {
  font-family: $secondary-font-bold;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  padding: 3px 0 3px 3px;
  border-radius: 4px;
  background-color: #36b37e;
  max-width: 90px;
  margin-top: 4px;
}
.treatment_container {
  padding: 20px 0px 0px;
}
.select_button {
  border-radius: 4px;
  background-color: #36b37e;
  padding: 3px;
  color: #fff;
  font-family: $secondary-font-regular;
  font-size: 11px;
  width: auto;
  display: inline;
}
.recom_text {
  color: #505f79;
  font-family: $secondary-font-regular;
  font-size: 14px;
  margin-top: 15px;
}
.treatment_text {
  color: #333;
  font-family: $secondary-font-regular;
  font-size: 16px;
}
.treatment_form {
  display: block;
  margin-top: 16px;
}
.treatment_input {
  width: 72px;
  height: 42px;
  background: #fff;
  border: 1px solid #c1c7d0;
  display: inline;
  float: left;
  margin-right: 10px;
}
.treatment_input input {
  width: 100%;
  height: 100%;
  border: 0;
}
.treatment_select {
  width: 72px;
  height: 42px;
  background: #fff;
  border: 1px solid #c1c7d0;
  display: inline;
  float: left;
}
.treatment_select select {
  width: 100%;
  height: 100%;
  border: 0;
}
.edit_wrapper:not(:first-child) {
  margin-top: 24px;
}
.nodata_container {
  font-family: $secondary-font-bold;
  font-size: 18px;
}
.error_message {
  display: block;
  color: $Error;
  padding-top: 4px;
  font-family: $secondary-font-regular;
  font-size: 14px;
}

.yes_btn {
  width: 140px;
  border-radius: 0px;
}

.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}