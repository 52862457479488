@import '../../../styles/standard.module.scss';
@import '../../../styles/common.module.scss';

@include response-above(sm) {
    .admin_subheading {
        font-size: $Heading-2;
        font-family: $primary-font-regular;
        color: $Plain-text;
    }
    .header {
        position: fixed;
        width: 100%;
        z-index: 1000;        
        display:flex;        
    }
    .custom_nav {
        background-color: $PrimaryColor-text;
        width:100%;
    }
    .admin_heading{
        font-size: 20px;
    }
}
@include response-above(lg) {
    .admin_subheading {
        font-size: $Heading-1;
        font-family: $primary-font-regular;
        color: $PrimaryColor-text;
    }
    .header {
        position: relative;
        margin-left: 0;
        display:flex;
        padding:25px 0 10px;
    }
    .custom_nav {
        background-color: transparent;
    }
    .admin_heading {
        font-size: 36px;
        color: $PrimaryColor-text;
        font-family: $primary-font-bold;
        padding-left: 0;
        margin-bottom: 0;
        display: inline-flex;
    }
}

.admin_meta{
    font-size: 18px;
    font-weight: normal;
    margin: 0px 10px 15px 15px;
    span{
        margin-right: 5px;
        b{
            font-weight: 600;
        }
    }
}

.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;    
}

.content{
    padding: 0px 15px;
}

.id_block{
    font-size: 19px;
    margin-left: 15px;
    margin-bottom: 20px;
    font-weight: normal;
}

.col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.select_wrapper {
    height: 37px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background: $Plain-bg url('../../../static/images/down-arrow.png') right 10px bottom 10px no-repeat;
    span{
        white-space: pre-line !important;
    }
}

.basic_select {
    width: 100%;
    height: 37px;
    padding: 0 30px 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}

.field_max{
    max-width: 280px;
}

.formulary_label{
    font-family: $secondary-font-regular;
    font-size: 16px;
}

.blocks{
    .left_item{
        width:45%;
        float:left;
        height:525px;
        .product_cat, .my_form {
            position: relative;
            top: 55px;
            font-size: 16px;
            color: #333333;
            font-family: $secondary-font-regular;
        }    
        .my_form_align{
            margin-bottom: -9px;
        }
    }
    
    .add_block{
        width:10%;
        float:left;
        height:400px;
        padding:20px 10px;
        margin-top: 75px;
        text-align: center;
        .btn_items, 
        .btn_items:hover {
            width: 70px;
            height: 42px;
            margin: 0;
            border-radius: 2px;
            background-color: $PrimaryButton-bg;
            font-family: $primary-font-regular;
            color: $Plain-text !important;
            font-size: 16px;
            margin-top: 20px;
            img{
                margin: -2px 3px 0 3px;
            }
        } 
    }
}

.proCatPos{
    margin-top:10% !important;
    margin-bottom: -8px !important;
    padding-left: 5px;
}

.procatPosMyForm {
    margin-top: -6px !important; 
}

.loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
    
}

.back_btn a {
    @include response-above(sm) {
        position: fixed;
        top:22px;
        left:25px;
        background:url(../../../static/images/back-arrow.png) center left no-repeat;
        text-indent: 999px;
    }
    @include response-above(lg) {
      position: static;
      top:auto;
      left:auto;
      color:$PrimaryColor-text !important;
      background:url(../../../static/images/left_arrow.png) center left no-repeat;
      padding-left: 10px;
      font-family: $secondary-font-regular;
      font-size:16px;
    }
}

.form_error_message{
    background-color: $bg-color-alert;
    color: $Plain-text;
    border-radius: 4px;
}
.accordion_card_header {
    background-color: #505f79;
    height: 56px;
    padding: 0 15px 0 0;
    cursor: pointer;

    .accordion_button {
        color: white;
        &:hover {
            color: white;
        }
    }
    svg {
        fill: white;
        transform: rotate(180deg);
    }
}
.thead {
    background-color: #505f79;
    color: white;
    padding: 16px 0px 16px 16px;
    font-weight: bold;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.accordion_group{
    margin-top: 57px;
}
.thead_filter{
    // float: right!important;
    margin-top: -14px!important;
    margin-left: 65%!important;
}
.thead_addmaterial{
    // margin-top: -14px!important;
    margin-right: 15px!important;
    cursor: pointer;
    text-decoration: underline;
}
.addMaterialFormulary{
    cursor: pointer;
    text-decoration: underline;
}

.formularyBox{
    padding: 21px 30px 10px 30px;
    background-color: #e6efff;
    p{
        color: #818181;
        font-size: 18px;
        font-weight: 500;
    }
}
.formularyNamePop{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  padding-left: 5px;
}
.formularyNamePopLine{
    margin-top: -5px;
}
.filterModelPop{
    padding: 30px 15px;
    border: 2px solid #7a7a7a;
    box-shadow: 5px 10px 20px 0 rgb(0 0 0 / 46%);
    border: 1px solid #e3e3e3;
    background-color: #ffffff;
    border-radius: 2px !important;
}
.addMaterialModelPop{
    padding: 30px 15px 20px 15px;
    border: 2px solid #7a7a7a;
    box-shadow: 5px 10px 20px 0 rgb(0 0 0 / 46%);
    border: 1px solid #e3e3e3;
    background-color: #ffffff;
    border-radius: 2px !important;
    // height: 400px;
    // max-height: 500px;  
    .x_icon{
        margin-top: -4px;
        float: right;
        margin-right: 15px;
        cursor: pointer;
    }  
}

.addMaterialModelPopHeight{
    height: 400px;
    max-height: 500px;
}

.search_icon_image {
    top: 8px;
    left: 11px;
  }
  .pl40 {
    padding-left: 40px;
    height: 40px;
    border-radius: 2px;
  }

.categoryDescription{
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    background-color: #505f79;
    color: #fff;
    padding: 15px 10px;
    align-items: center;
    // cursor: pointer;
    h2{
        font-size: 19px;
    }
    i{
        cursor: pointer;
    }
}
.familyDescription{
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    background-color: #505f79;
    color: #fff;
    padding: 15px 10px;
    align-items: center;
    // cursor: pointer;
    h2{
        font-size: 19px;
    }
    i{
        cursor: pointer;
    }
}

.categoryDescriptionList{
    padding: 15px 10px;
    align-items: center;
    font-size: 14px;
    border: 1px solid #bdbdbd;
}
.familyDescriptionList{
    padding: 15px 10px;
    align-items: center;
    font-size: 14px;
    border: 1px solid #bdbdbd;
}

.checkboxRound{
    // width: 1.3em;
    // height: 1.3em;
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}
.checkboxRound:checked {
    background-color: gray;
}




.tbody {
    padding: 5px 10px 20px 10px;
    overflow-y: scroll;
    max-height: 400px !important;
    min-height: 400px !important;
    border: 1px solid #ccc;
    
}
.trow {
    white-space: nowrap;
    border-bottom: 1px solid #cccccc;
    height: 40px;
    gap: 8px;
    font-size: 14px;
}
.checkBoxContent{
    border-bottom: 1px solid #c6c6c6;
    h4{
        font-size: 16px;
        color: #505f79;        
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        // padding-bottom: 5px;
    }
}
.custom_checkbox {
    min-height: 1rem !important;
    cursor: pointer;
    margin-top: -1px;
}
.custom_control_input {
    &:checked ~ .custom_control_label {
      &::before {
        color: $Plain-text;
        border-color: $border-box;
        background-color: $CheckBox-bg !important;
      }
    }
    z-index: 1 !important;
    cursor: pointer;
  }
  .custom_checkbox .custom_control_label::before {
    border-radius: 0;
    cursor: pointer;
  }
  .card_body {
      padding: 0 0 5px 0;
  }
  .cost_input {
      margin-left: 5px;
      cursor: pointer;
  }
  .fetch_uparrow img, .fetch_downarrow img{
    height: 5px;
    width: 6px;
    
  }
 .arrow_sec{
    display: inline-grid;
    position: absolute;
    margin-top: -6px;
 }

 .fetch_uparrow , .fetch_downarrow {
    height: 10px;
    cursor: pointer;
    width: 10px;
  }
  .searchBar{
    margin-left: 201px;
    margin-bottom: -40px;
  }
  .fetch_downarrow {
    margin-top: -3px;
}
.searchBar {
    margin-left: 201px;
    margin-bottom: -30px;
  }
  .searchPositon {
   top: -5px;
   font-size: small;
   z-index: 100;
  }
  #matCodeMul .multiselect-container {
    position: relative;
    text-align: left;
    width: 100%;
    border: 1px solid #c1c7d0;
  }
  .select_SearchWrapper{
    height: 37px;
    border-radius: 2px;
    // margin-top: -22px;
    //border: solid 1px $border-color;
    background: $Plain-bg url('../../../static/images/down-arrow.png') right 10px bottom 10px no-repeat;
    span{
        white-space: pre-line !important;
    }
    position: absolute;
    margin-top: 37px;
    margin-right: 20px;
    right: 0;
}

.import_block {
    width: auto;  
    cursor: pointer;
    border: 0;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    label{
        cursor: pointer;
        margin-bottom: 0;
    }
    img{
        margin-right:5px;
    }
}














.custom_modal_size {
    // max-width: 967px;
    max-width: 500px;
  } 
  
.custom_modal_size_import_heading{
    background-color: #fff;
  }
.importFormularyPopup{
    padding: 20px 0;
}
.importFormularyPopupBrowser{
    padding: 55px 0;
    background-color: #f4f4f4;
}
.errorProductMessage{
    color: red;
}


















  .model_import_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
  }
  .label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    white-space: nowrap;
  }
  .input_field,
.input_field:focus {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}
.label_cutom  {
    font-family: $secondary-font-bold;
    white-space: nowrap;
    font-size: 1rem;
    color: #333333;
  }
.label_cutom span {
    color: $Error !important;
  }
  .file_upload{
    background-color: #ffffff;
    padding: 5px 20px 5px 20px;
    border: 0px solid #ccc;
    box-shadow: 0 1px 5px #000;
    cursor: pointer;
    font-family:$primary-font-bold;
    color: #333333;
    width: 140px;
    height: 45px;
    border-radius: 25px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}
.file_upload:hover{
    box-shadow: 0 2px 8px #000;
}
.error_message {
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px !important;
}

.documentView {
    padding: 5px 0;
    }
    
    .documentView > img {
    margin-left: -5px;
    margin-right: 5px;
    max-width: 25px;
    margin-top: -3px;
    }
    
    .documentView a:hover {
    color: #3058db;
    text-decoration: none;
    font-weight: bold;
    }
    
    .documentView > span {
    margin-left: 10px;
    cursor: pointer;
    }
    .custom_control_inline{
        padding-top: 10px;
    }

  
    .custom_modal_suc {
        max-width: 720px;
        }

    .confirm_suc h1,.confirm_suc h2{
        color: $hint-success-text;
        font-family: $primary-font-bold;
    }