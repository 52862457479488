@import "../../../styles/standard.module.scss";

.viewMode_container {
  padding-top: 20px;
  color: $PrimaryColor-text;
  padding-right: 24px;
}
.lab_container {
  font-size: $Typography-2;
  font-family: $secondary-font-regular;
}
.nodata_msg {
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
  padding-top: 32px;
  max-width: 620px;
}
.edit_icon {
  font-size: $Typography-3;
  font-family: $secondary-font-regular;
}
.edit_icon:hover {
  cursor: pointer;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  cursor: pointer;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}

.tab_edit img {
  max-width: 20px;
  margin-right: 3px;
}
.e_icon_space {
  margin-right: 5px;
}
.cont_spac {
  padding-top: 0;
}
.botton_spac {
  font-size: $Typography-2;
  font-family: $secondary-font-regular;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}
.custom_basic_select {
  width: 230px;
  height: 40px;
  padding: 0 0 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: transparent;
  appearance: none;
  outline: none;
}
.label_spec {
  font-size: $Typography-2;
  padding-top: 0px;
  padding-bottom: 0px;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 0 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: transparent;
  appearance: none;
  outline: none;
}
.select_wrappers div:first-of-type {
  border: solid 1px $Error;
  border-radius: 2px;
}
.inline_block {
  display: inline;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-text;
}
.basic_input:focus {
  outline: none;
}
.custom_control_label {
  position: relative;
  margin-top: -5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 18px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_label::after {
  position: absolute;
  top: 11px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 11px;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 11px;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  width: 140px;
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  margin-right: 32px;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  width: 140px;
  height: 42px;
  padding: 10px 0px 10px;
  border-radius: 2px;
  border: solid 1px $tertiary-text !important;
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.select_wrapper,
.select_wrapper:active,
.select_wrapper:focus {
  background: $Plain-bg url("../../../static/images/down-arrow.png") right 10px
    center no-repeat !important;
  border-radius: 2px;
  min-height: 40px;
  height: auto;
  border: 1px solid $bdr-color-tertiary !important;
}
.mat_lab {
  padding-right: 5px;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
.border_color {
  border:solid 1px #c1c7d0 !important;
}

.yes_btn {
  width: 140px;
  border-radius: 0px;
}

.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}