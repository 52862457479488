@import '../../../styles/standard.module.scss';

.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 0 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
  outline: none;
}
.col_spec {   
    font-size: $Typography-2;
    font-family: $secondary-font-regular;
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}
.select_wrapper div:first-of-type {
  border: solid 1px #c1c7d0;
  border-radius: 2px;
  background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
  min-height: 40px;
  height: auto;
  background-color: $Plain-bg;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  @include btn_secondary; 
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  @include btn_primary;
  margin-right: 32px;
}
.viewMode_container {
    padding-top: 20px;
    color: $PrimaryColor-text;
    padding-right: 24px;
}
.edit_icon {
  font-size: $Typography-3;
  font-family: $secondary-font-regular;
}
.edit_icon:hover {
  cursor: pointer;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  cursor: pointer;
  z-index:1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}

.tab_edit img{
  max-width: 20px;
  margin-right:3px;
}
.lab_container {
  font-size: $Typography-2;
  font-family: $secondary-font-regular;
}
.e_icon_space{
  margin-right: 5px;
}
.botton_spac {
  margin-bottom: 30px;
  font-size: $Typography-2;
  font-family: $secondary-font-regular;
}
.cont_spac {
  padding-top: 20px;
}
.nodata_msg {
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
  padding-top: 32px;
  max-width: 620px;
}
.custom_control_label {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family:$secondary-font-regular;
  font-size: 18px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.custom_control_input.is_invalid~.custom_control_label::before,
.was_validated .custom_control_input:invalid~.custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid~.custom_control_label,
.was_validated .custom_control_input:valid~.custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked~.custom_control_label::before,
.was_validated .custom_control_input:valid:checked~.custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_label::after {
  position: absolute;
  top: 11px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 11px;
}
.custom_control_inline {
  height: 22px;
}

.custom_control_inline .custom_control_label {
  font-family: $secondary-font-bold;
  color: $text-color;
  font-size: 14px;
}
.custom_control_input:checked~.custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 11px;
} 
.custom_basic_select {
  width: 230px;
  height: 40px;
  padding: 0 0 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
  outline: none;
}
#instrumentsUsed {
  border: solid 1px #c1c7d0 !important;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-text
}
.basic_input:focus {
  outline: none;
}
.label_spec {
  font-size: $Typography-1;
  padding-top: 0px;
  padding-bottom: 0px;
}
.select_wrapper_custom {
  border: solid 1px $tertiary-text;
}
.custom_col_spec {
  font-size: $Typography-1;
  font-family: $secondary-font-regular;
}
.inline_block {
  display: inline;
}
.select_wrappers div:first-of-type {
  border: solid 1px #de350b;
  border-radius: 2px;
}
.first_letter_capital{
  text-transform: capitalize;
  font-weight: bold;
}

.select_wrapper {
  background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
}

.yes_btn {
  width: 140px;
  border-radius: 0px;
}

.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}